import type { TSportEvent } from 'markets-store'
import { sortBy } from '@st/utils'
import { useHomeStore } from '../../stores/useHomeStore'

export function useEventOfTheDay() {
  const { mainEventsIds } = storeToRefs(useHomeStore())
  const sportEvents = useSportEvents()

  const eventOfTheDay = computed<TSportEvent | undefined>(() => {
    const ids = new Set(mainEventsIds.value)
    const events = sportEvents.value.filter((event) => ids.has(event.id))
    const sortedEvents = sortBy(events, (event) => [event.scheduled])
    return sortedEvents[0]
  })

  return { eventOfTheDay }
}
