import type { TSportEvent } from 'markets-store'
import { sortBy } from '@st/utils'
import { useHomeStore } from '../../stores/useHomeStore'

export function useSecondEvents() {
  const { secondEventsIds } = storeToRefs(useHomeStore())
  const sportEvents = ref(useSportEvents())

  const secondEvents = computed<TSportEvent[]>(() => {
    const ids = new Set(secondEventsIds.value)
    const events = sportEvents.value.filter((event) => ids.has(event.id))
    const sortedEvents = sortBy(events, (event) => [event.scheduled])
    return sortedEvents.slice(0, 5)
  })

  return { secondEvents }
}
