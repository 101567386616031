import type { CustomMenuItem } from 'markets-store'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useHomeStore } from '../../stores/useHomeStore'

export interface TopTournament extends CustomMenuItem {
  backgroundImage: string
}

export function useTopTournaments() {
  const { customTournamentsIds } = storeToRefs(useHomeStore())
  const { settings } = storeToRefs(useSettingsStore())
  const customMenu = useCustomMenu()

  const topTournaments = computed<TopTournament[]>(() =>
    customTournamentsIds.value.reduce<TopTournament[]>((tournaments, id) => {
      const tournament = customMenu.value.findById('tournament', id)
      if (tournament)
        tournaments.push({
          ...tournament,
          backgroundImage: `${settings.value?.websiteEndpoint}top-sb-sport-events-tournament-${tournament.id}.png`,
        })
      return tournaments
    }, []),
  )

  return { topTournaments }
}
